// Types
import type { MeadowThemeCategoryPurposes } from '@taskrabbit/meadow-web/lib/Theme';
import type { ICategoryFamilyFields } from '~types/_generated/contentful';

export const COLOR_KEY_MAP: {
  readonly [key in ICategoryFamilyFields['contentfulName']]: keyof MeadowThemeCategoryPurposes;
} = {
  'CategoryFamily - Assembly': 'assembly',
  'CategoryFamily - Cleaning': 'cleaning',
  'CategoryFamily - Electrical': 'electrical',
  'CategoryFamily - Home Repairs': 'homeImprovements',
  'CategoryFamily - Mounting': 'mounting',
  'CategoryFamily - Moving': 'moving',
  'CategoryFamily - Outdoor Help': 'outdoorMaintenance',
  'CategoryFamily - Painting': 'painting',
  'CategoryFamily - Plumbing': 'plumbing',
  'CategoryFamily - Trending': 'trending',
};

export const CATEGORY_ICON_MAP: {
  readonly [key in ICategoryFamilyFields['contentfulName']]: string;
} = {
  'CategoryFamily - Assembly': 'tools-32',
  'CategoryFamily - Cleaning': 'broom-32',
  'CategoryFamily - Electrical': 'electrical-help-32',
  'CategoryFamily - Home Repairs': 'hammer-32',
  'CategoryFamily - Mounting': 'drill-32',
  'CategoryFamily - Moving': 'truck-32',
  'CategoryFamily - Outdoor Help': 'tree-32',
  'CategoryFamily - Painting': 'paint-roller-32',
  'CategoryFamily - Plumbing': 'plumbing-32',
  'CategoryFamily - Trending': 'trending-32',
};

export const DEFAULT_CATEGORY = 'CategoryFamily - Assembly';
