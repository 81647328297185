// External
import Box from '@taskrabbit/meadow-web/lib/Box';
import Container from '@taskrabbit/meadow-web/lib/Container';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import Image from 'next/image';

// Internal
import contentfulImageLoader from '~utils/contentfulImageLoader';

// Types
import type { HomeIndexData } from '~server/trpc/router/page/home/types';

const ImageContainer = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  height: '64px',
  position: 'relative',
  width: '64px',

  [breakpoints.up('md')]: {
    height: '90px',
    position: 'absolute',
    width: '90px',
  },
}));

const StyledContainer = styled(Container)(
  ({ theme: { breakpoints, spacing } }) => ({
    margin: '0 auto',
    paddingLeft: '4%',

    [breakpoints.up('sm')]: {
      paddingLeft: '4%',
    },

    [breakpoints.up('lg')]: {
      margin: 'inherit',
      maxWidth: '1440px',
      paddingLeft: spacing(19),
    },

    [breakpoints.up(1440)]: {
      margin: '0 auto',
    },
  })
);

const Logo = ({ imageText, imageUrl }: HomeIndexData['logo']) => (
  <StyledContainer sx={{ pt: 2 }}>
    <ImageContainer>
      <Image
        alt={imageText}
        fill
        loader={contentfulImageLoader}
        priority
        sizes="(max-width: 600px) 64px, 256px"
        src={imageUrl}
      />
      ;
    </ImageContainer>
  </StyledContainer>
);

export default Logo;
