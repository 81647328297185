// External
import styled, {
  withTypographyRules,
} from '@taskrabbit/meadow-web/lib/Theme/styled';
import Container from '@taskrabbit/meadow-web/lib/Container';
import Divider from '@taskrabbit/meadow-web/lib/Divider';
import Typography from '@taskrabbit/meadow-web/lib/Typography';

// Page
export const StyledPage = styled('div')(
  ({
    theme: {
      meadow: {
        purpose: { background },
      },
    },
  }) => ({
    backgroundColor: background.main,
    position: 'relative',
  })
);

// Components
export const StyledContainer = styled(Container)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledDivider = styled(Divider)(
  ({
    theme: {
      meadow: {
        purpose: { component },
      },
      spacing,
    },
  }) => ({
    borderColor: component.disabled,
    marginBottom: spacing(3), // 24px
  })
);

export const MobileWrapper = styled('div')(({ theme: { breakpoints } }) => ({
  [breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const DesktopWrapper = styled('div')(({ theme: { breakpoints } }) => ({
  [breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const RelativeContainer = styled('div')(() => ({
  position: 'relative',
}));

export const StyledHeader = styled(Typography)(
  ({
    theme: {
      meadow: {
        purpose: { impact },
      },
    },
  }) => ({
    color: impact.emphasis,
  })
);

export const StyledLink = styled('a')(
  ({
    theme,
    theme: {
      meadow: {
        purpose: { primary },
      },
    },
  }) =>
    withTypographyRules(theme, 'subtitle4', {
      color: primary.main,
      textDecoration: 'none',
    })
);
