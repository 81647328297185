// External
import IcoMoon from '@taskrabbit/meadow-web/lib/IcoMoon';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';

// Internal
import { CATEGORY_ICON_MAP } from './constants';
import { RelativeContainer } from '../index.styled';

// Types
import type { ICategoryFamilyFields } from '~types/_generated/contentful';

const AbsoluteIcon = styled(IcoMoon)(({ theme: { breakpoints, spacing } }) => ({
  fontSize: 24,
  left: '0',
  marginLeft: spacing(2.375),
  marginTop: spacing(1.5),
  position: 'absolute',
  top: '0',

  [breakpoints.up('md')]: {
    fontSize: 32,
    marginLeft: spacing(1.875),
    marginTop: spacing(1.125),
  },
}));

const renderBackgroundSvg = (color: string) => (
  <svg
    fill="none"
    height="50"
    viewBox="0 0 60 50"
    width="60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8962 5.27305C25.849 -1.46104 32.1704 -0.338692 34.462 1.06424C39.9101 1.06424 51.4547 1.90601 54.049 5.27305C57.2919 9.48186 55.3461 21.0561 58.589 25.8662C61.8319 30.6762 56.2542 38.4926 44.4501 46.4593C32.646 54.4259 13.8374 46.4593 9.81618 46.4593C5.79502 46.4593 1.51443 40.146 0.606422 32.1794C-0.301583 24.2127 4.7573 22.1083 9.81618 21.0561C14.8751 20.0039 10.2053 13.6907 18.8962 5.27305Z"
      fill={color}
    />
  </svg>
);

const CategoryIconWithBackground = ({
  categoryFamilyId,
  selected = false,
  selectedColor,
  svgColor,
  unselectedColor,
}: {
  categoryFamilyId: ICategoryFamilyFields['contentfulName'];
  selected: boolean;
  selectedColor: string;
  svgColor: string;
  unselectedColor: string;
}) => {
  const iconColor = selected ? selectedColor : unselectedColor;
  const svgFill = selected ? svgColor : 'none';
  const iconName = CATEGORY_ICON_MAP[categoryFamilyId];

  return (
    <RelativeContainer sx={{ height: { sm: '52px', xs: '46px' } }}>
      {renderBackgroundSvg(svgFill)}
      <AbsoluteIcon
        className="categoryFamilyElementChild"
        color={iconColor}
        name={iconName}
      />
    </RelativeContainer>
  );
};

export default CategoryIconWithBackground;
