// External
import Image from 'next/image';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';

// Internal
import topRightShape from '~public/next-images/background_shapes/top_right_shape.svg';
import topLeftShape from '~public/next-images/background_shapes/top_left_shape.svg';
import midRightShapeFirst from '~public/next-images/background_shapes/mid_right_shape_first.svg';
import midRightShapeSecond from '~public/next-images/background_shapes/mid_right_shape_second.svg';
import midLeftShape from '~public/next-images/background_shapes/mid_left_shape.svg';
import botRightShape from '~public/next-images/background_shapes/bot_right_shape.svg';
import botLeftShape from '~public/next-images/background_shapes/bot_left_shape.svg';

// Components
/* We want this to take the whole size of its parent so its own children can play
around with top, right, bottom and left with ease */
const BigContainer = styled('span')`
  @media (max-width: 1280px) {
    display: none;
  }
  bottom: 0;
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
`;

const BackgroundShape = styled(Image)`
  position: absolute;
  user-select: none;
  z-index: 0;
`;

const BackgroundShapesContainer = () => {
  const midLeftShapeTop = 2053;
  const midRightShapeFirstTop = 1240;
  const midRightShapeSecondTop = 3440;

  return (
    <BigContainer>
      {/* Top Right */}
      <BackgroundShape
        alt=""
        priority={true}
        src={topRightShape}
        sx={{
          right: 0,
          top: 0,
        }}
      />
      {/* Top Left */}
      <BackgroundShape
        alt=""
        priority={true}
        src={topLeftShape}
        sx={{
          top: 50,
        }}
        width="145"
      />
      {/* Mid Right First */}
      <BackgroundShape
        alt=""
        src={midRightShapeFirst}
        sx={{
          right: 0,
          top: midRightShapeFirstTop,
          zIndex: 1,
        }}
      />
      {/* Mid Right Second */}
      <BackgroundShape
        alt=""
        src={midRightShapeSecond}
        sx={{
          right: 0,
          top: midRightShapeSecondTop,
          zIndex: 1,
        }}
      />
      {/* Mid Left */}
      <BackgroundShape
        alt=""
        src={midLeftShape}
        sx={{
          top: midLeftShapeTop,
          zIndex: 1,
        }}
      />
      {/* Bottom Right */}
      <BackgroundShape
        alt=""
        src={botRightShape}
        sx={{
          bottom: 105,
          right: 0,
        }}
      />
      {/* Bottom Left */}
      <BackgroundShape
        alt=""
        src={botLeftShape}
        sx={{
          bottom: -51,
          clip: 'rect(0px, 160px, 130px, 0px)',
          zIndex: 0,
        }}
        width="160"
      />
    </BigContainer>
  );
};

export default BackgroundShapesContainer;
