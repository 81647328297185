// External
import ButtonChoice from '@taskrabbit/meadow-web/lib/ButtonChoice';
import Container from '@taskrabbit/meadow-web/lib/Container';
import Grid from '@taskrabbit/meadow-web/lib/Grid';
import IcoMoon from '@taskrabbit/meadow-web/lib/IcoMoon';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import Typography from '@taskrabbit/meadow-web/lib/Typography';

// Internal
import {
  GET_HELP_TODAY_SECTION,
  PAGE_HOME,
  RECOMMENDATION_CATEGORY_PICKED,
  SERVICE_PAGE_LINK_CLICKED,
} from '~hooks/useSegment/eventNames';
import { StyledLink } from './index.styled';
import { getBookingFlowUrl } from './utils/helpers';

// Types
import type { HomeIndexData } from '~server/trpc/router/page/home/types';
import type { IkeaPhase } from '~services/IkeaPhaseService';
export type GetHelpTodayProps = HomeIndexData['getHelpToday'] & {
  ikeaPhase: IkeaPhase;
  locale: string;
};

// Hooks
import useSegment from '~hooks/useSegment';

// Styled Components
const GetHelpTodayContainer = styled(Container)(
  ({ theme: { breakpoints, spacing } }) => ({
    paddingBottom: spacing(6),
    paddingTop: spacing(6),

    [breakpoints.up('md')]: {
      paddingBottom: spacing(11),
      paddingTop: spacing(11),
    },
  })
);

const DesktopButtonChoice = styled(ButtonChoice)(
  ({ theme: { breakpoints } }) => ({
    [breakpoints.down('md')]: {
      display: 'none',
    },
  })
);

const MobileButtonChoice = styled(ButtonChoice)(
  ({ theme: { breakpoints } }) => ({
    [breakpoints.up('md')]: {
      display: 'none',
    },
  })
);

const StyledChevronRight = styled(IcoMoon)(({ theme: { spacing } }) => ({
  fontSize: 16,
  marginLeft: spacing(1),
}));

const StyledGrid = styled(Grid)(({ theme: { breakpoints, spacing } }) => ({
  marginBottom: spacing(4),

  [breakpoints.up('md')]: {
    marginBottom: spacing(6),
  },
}));

const StyledHeader = styled(Typography)(
  ({ theme: { breakpoints, meadow, spacing } }) => ({
    color: meadow.purpose.impact.emphasis,
    marginBottom: spacing(4),

    [breakpoints.up('md')]: {
      marginBottom: spacing(6),
    },
  })
);

// Main Component
const GetHelpToday = ({
  header,
  ikeaPhase,
  linkText,
  linkUrl,
  locale,
  services,
}: GetHelpTodayProps) => {
  const { trackEvent } = useSegment();

  const trackServicePillClick = ({
    name,
    v3CategoryId,
    v3TaskTemplateId,
  }: {
    name: string;
    v3CategoryId?: number;
    v3TaskTemplateId?: number;
  }) => {
    trackEvent({
      name: RECOMMENDATION_CATEGORY_PICKED,
      properties: {
        job_category_id: v3CategoryId,
        page: PAGE_HOME,
        task_label: name,
        task_template_id: v3TaskTemplateId,
        trigger_location: GET_HELP_TODAY_SECTION,
      },
    });
  };

  const trackSeeAllServicesButtonClick = (linkText: string) => {
    trackEvent({
      name: SERVICE_PAGE_LINK_CLICKED,
      properties: {
        link_label: linkText,
        page: PAGE_HOME,
        trigger_location: GET_HELP_TODAY_SECTION,
      },
    });
  };

  const getServiceLinkUrl = ({
    v3CategoryId,
    v3TaskTemplateId,
  }: {
    v3CategoryId?: number;
    v3TaskTemplateId?: number;
  }) => {
    if (v3CategoryId && v3TaskTemplateId) {
      return getBookingFlowUrl(
        v3CategoryId,
        ikeaPhase,
        locale,
        v3TaskTemplateId
      );
    }
  };

  return (
    <GetHelpTodayContainer data-testid="get-help-today-container">
      <StyledHeader variant="h2"> {header} </StyledHeader>

      <StyledGrid alignItems="center" container spacing={3}>
        {services.map((service) => {
          const serviceLinkUrl = getServiceLinkUrl(service);
          return (
            serviceLinkUrl && (
              <Grid item key={service.v3TaskTemplateId}>
                <div onClick={() => trackServicePillClick(service)}>
                  <DesktopButtonChoice href={serviceLinkUrl}>
                    {service.name}
                  </DesktopButtonChoice>
                  <MobileButtonChoice href={serviceLinkUrl} size="small">
                    {service.name}
                  </MobileButtonChoice>
                </div>
              </Grid>
            )
          );
        })}
      </StyledGrid>

      {/* TODO: Use relative links after migrating the service pages to tr_web/next */}
      <StyledLink
        href={linkUrl}
        onClick={() => trackSeeAllServicesButtonClick(linkText)}
        target="_blank"
      >
        {linkText}
      </StyledLink>
      <StyledChevronRight name="chevron-16-right" />
    </GetHelpTodayContainer>
  );
};

export default GetHelpToday;
