// External
import useEffectOnce from 'react-use/lib/useEffectOnce';
import { useRouter } from 'next/router';

// Internal
import type {
  HomeIndexData,
  HomeIndexMeta,
} from '~server/trpc/router/page/home/types';
import { fireMetric, hasMarketingData, isExternal } from '~utils/metrics';
import { HOMEPAGE_VIEWED, PAGE_REFERRED } from '~services/MetricsBusService';
import { getNextLocale } from '~utils/helpers';

import { StyledPage } from '~pages/homepage/index.styled';

import BackgroundShapesContainer from '~pages/homepage/BackgroundShapesContainer';
import CategoriesContainer from '~pages/homepage/categories/CategoriesContainer';
import CategoryQuickStats from '~pages/homepage/CategoryQuickStats';
import GetHelpToday from '~pages/homepage/GetHelpToday';
import HeroHeadline from '~pages/homepage/HeroHeadline';
import HomeSearchBar from '~pages/homepage/HomeSearchBar';
import Logo from '~pages/homepage/Logo';
import HowItWorks from '~components/marketing/HowItWorks';
import PopularProjects from '~pages/homepage/PopularProjects';
import ProtectionSatisfactionProof from '~components/marketing/ProtectionSatisfactionProof';
import Reviews from '~components/marketing/Reviews';
import SchemaCodes from '~components/SchemaCodes';
import SEOContent from '~components/SEOHead';

import { PAGE_HOME } from '~hooks/useSegment/eventNames';

// Hooks
import useSegment from '~hooks/useSegment';

// Types
export interface HomeIndexPageProps {
  bff: HomeIndexData;
  meta: HomeIndexMeta;
}

// Component
const HomeIndexPage = ({ bff, meta }: HomeIndexPageProps) => {
  const { trackPage } = useSegment();
  const router = useRouter();
  const locale = getNextLocale(router);

  useEffectOnce(() => {
    fireMetric(HOMEPAGE_VIEWED, { locale });
    trackPage({ name: PAGE_HOME });

    if (isExternal() || hasMarketingData()) {
      fireMetric(PAGE_REFERRED, { locale });
    }
  });

  return (
    <>
      <SchemaCodes locale={meta.locale} schemas={bff.schemaCodes} />
      <SEOContent {...bff.seo} locale={meta.locale} />
      <StyledPage>
        <BackgroundShapesContainer />
        <Logo {...bff.logo} />
        <HeroHeadline header={bff.header} />
        <HomeSearchBar
          defaultSearchResults={bff.defaultSearchResults}
          ikeaPhase={bff.ikeaPhase}
          locale={meta.locale}
          placeholder={bff.searchPlaceholder}
        />
        <CategoriesContainer {...bff.categoryFamilies} />
        <CategoryQuickStats {...bff.categoryQuickStats} />
        <PopularProjects {...bff.popularProjects} />
        <Reviews {...bff.reviews} />
        <ProtectionSatisfactionProof {...bff.satisfaction} />
        <HowItWorks {...bff.howItWorks} />
        <GetHelpToday {...bff.getHelpToday} locale={meta.locale} />
      </StyledPage>
    </>
  );
};

export default HomeIndexPage;
