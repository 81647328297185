// External
import Head from 'next/head';

// Internal
import getDomain from '~utils/getDomain';
import type {
  OrganizationSchemaCode,
  SchemaCodes,
} from '~server/trpc/router/page/home/types';
import type { ProductSchemaCodeResponse } from '~server/downstream/v3/responses/schemaCodes';

type SchemaCodesProps = {
  locale: string;
  schemas: SchemaCodes;
};

const SchemaCodesComp = ({
  schemas: { organizationSchemaCode, productSchemaCode },
  locale,
}: SchemaCodesProps) => {
  const localizedDomain = getDomain(locale);

  const hydrateOrganizationSchemaCode = (schema: OrganizationSchemaCode) => {
    schema['url'] = localizedDomain.origin;
    schema['logo'] =
      'https://assets.taskrabbit.com/v3/assets/web/logos/tr_full_new.svg';

    return schema;
  };

  const renderScriptTag = (
    content: OrganizationSchemaCode | ProductSchemaCodeResponse
  ) => (
    <script
      dangerouslySetInnerHTML={{ __html: JSON.stringify(content) }}
      type="application/ld+json"
    />
  );

  return (
    <Head>
      {renderScriptTag(hydrateOrganizationSchemaCode(organizationSchemaCode))}
      {renderScriptTag(productSchemaCode)}
    </Head>
  );
};

export default SchemaCodesComp;
