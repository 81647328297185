// External
import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { getCookie } from 'cookies-next';
import qs from 'qs';

// Internal
import getDefaultLayout from '~layouts/Default';
import { getNextLocale } from '~utils/helpers';
import HomeIndexPage from '~components/pages/HomeIndexPage';
import ssgProxy from '~server/trpc/ssgProxy';
import { getSharedPageProps } from './common';

// Types
import type { SharedPageProps } from './common';
import type { FooterItems } from '~components/SiteFooter';
import type { MenuItems } from '~components/layout/SiteHeader';
import type { BffResponse } from '~server/bff';
import type {
  HomeIndexData,
  HomeIndexMeta,
} from '~server/trpc/router/page/home/types';

export type HomepageProps = InferGetServerSidePropsType<
  typeof getServerSideProps
>;

// GetProps
export const getServerSideProps: GetServerSideProps<
  SharedPageProps & {
    footerItems?: FooterItems;
    menuItems?: MenuItems;
    page: BffResponse<HomeIndexData, HomeIndexMeta>;
  }
> = async (context) => {
  const { req, res } = context;
  const sessionCookie = getCookie('session', { req, res });

  if (sessionCookie) {
    const queryString = qs.stringify(context.query);
    return {
      redirect: {
        destination: '/dashboard' + (queryString ? `?${queryString}` : ''),
        permanent: false,
      },
    };
  }

  const ssg = ssgProxy({ context, mode: 'dynamic' });
  const { experiments, features } = await getSharedPageProps(context);

  const locale = getNextLocale(context);

  const [bffHeaderData, bffFooterData, bffPageData] = await Promise.all([
    ssg.layout.root.header.fetch({ locale }),
    ssg.layout.root.footer.fetch(),
    ssg.page.home.index.fetch({
      decision: { experiments, features },
      locale,
      preview: !!context.preview,
    }),
  ]);

  if (bffPageData.error) throw new Error(bffPageData.error.message);

  return {
    props: {
      footerItems: bffFooterData.bff.footerItems,
      jotai: { experiments, features },
      menuItems: bffHeaderData.bff?.menuItems,
      page: bffPageData,
    },
  };
};

const Home = ({ page }: HomepageProps) => {
  if (!page.bff || !page.meta) return null;

  return <HomeIndexPage bff={page.bff} meta={page.meta} />;
};

Home.getLayout = getDefaultLayout;

export default Home;
