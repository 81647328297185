// External
import Container from '@taskrabbit/meadow-web/lib/Container';
import Stack from '@taskrabbit/meadow-web/lib/Stack';
import IcoMoon from '@taskrabbit/meadow-web/lib/IcoMoon';
import Image from 'next/image';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import Typography from '@taskrabbit/meadow-web/lib/Typography';

// Internal
import Section from '~/components/marketing/Section';
import contentfulImageLoader from '~utils/contentfulImageLoader';

import type { Image as ImageType } from '~server/types/content';

// Props Type
interface CategoryFamilyCopyAndPhotoProps {
  backgroundColor: string;
  image: ImageType;
  name: string;
  text1: string;
  text2: string;
}

// Styled Components
const DesktopStyledCategoryData = styled(Section)(
  ({
    theme: {
      breakpoints,
      meadow: {
        purpose: { borderRadius },
      },
      spacing,
    },
  }) => ({
    borderRadius: borderRadius.floating,
    padding: spacing(0, 0, 2, 0),

    [breakpoints.up('sm')]: {
      height: '574px',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '1110px',
      padding: '40px 40px 0 40px',
    },
  })
);

const DesktopStyledCategoryInfoContainer = styled('div')(
  ({
    theme: {
      meadow: {
        purpose: { borderRadius },
      },
      spacing,
    },
  }) => ({
    backgroundColor: '#FFF',
    borderRadius: borderRadius.surface,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '366px',
    justifyContent: 'space-evenly',
    margin: 'auto 0',
    padding: spacing(4, 5),
    position: 'absolute',
    top: 0,
    width: '383px',
    zIndex: '1',
  })
);

const MobileImageBackgroundContainer = styled('div')(() => ({
  height: '254px',
}));

const MobileImageBackground = styled(Section)(
  ({
    theme: {
      meadow: {
        purpose: { borderRadius },
      },
    },
  }) => ({
    borderRadius: borderRadius.floating,
    height: '100%',
    maxHeight: '254px',
    position: 'absolute',
    right: 0,
    width: '94vw',
  })
);

const StyledCategoryImageContainer = styled(Section)(
  ({
    theme: {
      breakpoints,
      meadow: {
        purpose: { borderRadius },
      },
      spacing,
    },
  }) => ({
    borderRadius: borderRadius.surface,
    height: '238px',
    overflow: 'hidden',
    width: '96vw',
    zIndex: 1,

    [breakpoints.up('sm')]: {
      borderRadius: borderRadius.input,
      height: '92%',
      marginLeft: spacing(10),
      maxHeight: '491px',
      maxWidth: '950px',
      position: 'relative',
      width: 'calc(100% - 80px)',
      zIndex: 0,
    },
  })
);

export const CategoryPhotoMobileWrapper = styled('div')(
  ({ theme: { breakpoints } }) => ({
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  })
);

export const CategoryPhotoDesktopWrapper = styled('div')(
  ({ theme: { breakpoints } }) => ({
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  })
);

// Section (default export)
const CategoryFamilyCopyAndPhoto = ({
  backgroundColor,
  image: { imageText, imageUrl },
  name,
  text1,
  text2,
}: CategoryFamilyCopyAndPhotoProps) => (
  <>
    <CategoryPhotoDesktopWrapper>
      <Container
        data-testid="category-copy-and-photo-container"
        disableGutters={true}
      >
        <DesktopStyledCategoryData backgroundColor={backgroundColor}>
          <DesktopStyledCategoryInfoContainer>
            <Typography variant="subtitle1">{name}</Typography>

            <Stack direction="row">
              <IcoMoon
                name="check-16"
                sx={{ mr: { md: 3, xs: 2 }, mt: 0.675 }}
              />
              <Typography variant="body1">{text1}</Typography>
            </Stack>

            <Stack direction="row">
              <IcoMoon
                name="check-16"
                sx={{ mr: { md: 3, xs: 2 }, mt: 0.675 }}
              />
              <Typography variant="body1">{text2}</Typography>
            </Stack>
          </DesktopStyledCategoryInfoContainer>
          <StyledCategoryImageContainer>
            <Image
              alt={imageText}
              fill={true}
              loader={contentfulImageLoader}
              priority={true}
              sizes="(max-width: 450px) 96vw, 950px"
              src={imageUrl}
              style={{ objectFit: 'cover', objectPosition: 'center' }}
            />
          </StyledCategoryImageContainer>
        </DesktopStyledCategoryData>
      </Container>
    </CategoryPhotoDesktopWrapper>
    <CategoryPhotoMobileWrapper>
      <Container>
        <Stack direction="row" sx={{ marginBottom: '20px' }}>
          <IcoMoon name="check-16" sx={{ mr: { md: 3, xs: 2 }, mt: 0.675 }} />
          <Typography variant="body1">{text1}</Typography>
        </Stack>

        <Stack direction="row" sx={{ mb: 4 }}>
          <IcoMoon name="check-16" sx={{ mr: { md: 3, xs: 2 }, mt: 0.675 }} />
          <Typography variant="body1">{text2}</Typography>
        </Stack>
      </Container>

      <MobileImageBackgroundContainer>
        <MobileImageBackground backgroundColor={backgroundColor} />
        <StyledCategoryImageContainer>
          <Image
            alt={imageText}
            fill={true}
            loader={contentfulImageLoader}
            priority={true}
            sizes="(max-width: 450px) 96vw, 950px"
            src={imageUrl}
            style={{ objectFit: 'cover', objectPosition: 'center' }}
          />
        </StyledCategoryImageContainer>
      </MobileImageBackgroundContainer>
    </CategoryPhotoMobileWrapper>
  </>
);
export default CategoryFamilyCopyAndPhoto;
