// External
import ButtonSecondary from '@taskrabbit/meadow-web/lib/ButtonSecondary';
import Image from 'next/image';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import Typography from '@taskrabbit/meadow-web/lib/Typography';
import { useAtom } from 'jotai';
import { useRouter } from 'next/router';

// Internal
import { ReviewItem } from '~components/marketing/ReviewItem';
import { StyledContainer, StyledSection } from './index.styled';
import contentfulImageLoader from '~utils/contentfulImageLoader';
import postalCodeAtom from '~jotai/atoms/postalCode';
import {
  BackgroundShapeDesktop,
  BackgroundShapeTabletLand,
  BigContainer,
} from '~components/pages/nearMe/BackgroundShapes';
import shapeDesktop from '~public/next-images/background_shapes/nearMe/reviews_left_desktop.svg';
import shapeTabLand from '~public/next-images/background_shapes/nearMe/reviews_right_tablet_land.svg';

// Types
import type {
  Header,
  Image as ImageType,
  Link,
  Review,
} from '~server/types/content';
import type { SxProps } from '@mui/system';
import type { TrackCtaClicked } from './types';

export type ReviewsProps = Header &
  Partial<ImageType> & {
    locale: string;
    reviews?: Review[];
    starRatingSx?: SxProps;
  } & Partial<Link> &
  Partial<TrackCtaClicked>;

// Styled Components
const StyledHeader = styled(Typography)(
  ({
    theme: {
      breakpoints,
      meadow: {
        purpose: { impact },
      },
      spacing,
    },
  }) => ({
    alignSelf: 'start',
    color: impact.emphasis,
    marginBottom: spacing(4),
    paddingRight: spacing(3),

    [breakpoints.up('md')]: {
      marginBottom: spacing(6),
    },
  })
);

const StyledReviewsItemsContainer = styled('ul')(
  ({ theme: { breakpoints, spacing } }) => ({
    '::-webkit-scrollbar': {
      display: 'none',
    },

    alignSelf: 'start',
    columnGap: spacing(3),
    display: 'flex',
    flexWrap: 'nowrap',
    marginBottom: spacing(3),
    overflowX: 'scroll',
    overflowY: 'hidden',
    paddingInlineStart: 0,
    width: '100%',

    [breakpoints.up('md')]: {
      columnGap: spacing(6),
      flexWrap: 'wrap',
      marginBottom: spacing(7),
      rowGap: spacing(6),
      width: '700px',
    },

    [breakpoints.up('lg')]: {
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      width: 'auto',
    },
  })
);

const StyledImageWrapper = styled('div')(
  ({ theme: { breakpoints, spacing } }) => ({
    margin: '0 auto',
    maxWidth: '354px',
    minHeight: spacing(7),
    position: 'relative',
    width: '100%',

    [breakpoints.up('sm')]: {
      maxWidth: '612px',
      minHeight: spacing(11.25),
    },
  })
);

const StyledButtonSecondary = styled(ButtonSecondary)(
  ({ theme: { breakpoints } }) => ({
    alignSelf: 'start',

    [breakpoints.up('md')]: {
      alignSelf: 'center',
    },
  })
);

// Main Component
const Reviews = ({
  header,
  hasBackgroundShape,
  imageText,
  imageUrl,
  linkText,
  linkUrl,
  locale,
  reviews,
  starRatingSx,
  trackCtaClicked,
}: ReviewsProps & {
  hasBackgroundShape?: boolean;
}) => {
  const [postalCode] = useAtom(postalCodeAtom);
  const router = useRouter();

  if (!reviews) return;

  const trackCtaClickedAndNavigate = async () => {
    if (trackCtaClicked) {
      trackCtaClicked('reviews', postalCode);
    }

    if (linkUrl) {
      router.push(linkUrl, undefined, { locale });
    }
  };

  return (
    <StyledContainer data-testid="reviews-container">
      <BackgroundShapes hasBackgroundShape={hasBackgroundShape} />
      <StyledSection>
        <StyledHeader variant="h2">{header}</StyledHeader>
        <StyledReviewsItemsContainer>
          {reviews.map((review) => (
            <ReviewItem
              key={review.id}
              locale={locale}
              starRatingSx={starRatingSx}
              {...review}
            />
          ))}
        </StyledReviewsItemsContainer>
        {imageUrl && (
          <StyledImageWrapper>
            <Image
              alt={imageText || ''}
              fill={true}
              loader={contentfulImageLoader}
              priority={true}
              sizes="(max-width: 600px) 354px, 612px"
              src={imageUrl}
              style={{ objectFit: 'contain' }}
            />
          </StyledImageWrapper>
        )}
        {linkText && linkUrl && (
          <StyledButtonSecondary onClick={() => trackCtaClickedAndNavigate()}>
            {linkText}
          </StyledButtonSecondary>
        )}
      </StyledSection>
    </StyledContainer>
  );
};

const BackgroundShapes = ({
  hasBackgroundShape,
}: {
  hasBackgroundShape?: boolean;
}) => {
  if (!hasBackgroundShape) {
    return null;
  }

  return (
    <BigContainer>
      <BackgroundShapeDesktop
        alt=""
        priority={true}
        src={shapeDesktop}
        sx={{
          left: '-20px',
          top: '-440px',
        }}
      />
      <BackgroundShapeTabletLand
        alt=""
        priority={true}
        src={shapeTabLand}
        sx={{
          paddingLeft: '1%',
          right: 0,
          top: '-405px',
        }}
      />
    </BigContainer>
  );
};

export default Reviews;
