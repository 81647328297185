// External
import { hasCookie } from 'cookies-next';
import SearchBar, {
  SearchBarResults,
} from '@taskrabbit/meadow-web/lib/SearchBar';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import useDebounce from 'react-use/lib/useDebounce';

// Internal
import { trpc } from '~utils/trpc';
import { getBookingFlowUrl } from './utils/helpers';
import useSegment from '~hooks/useSegment';
import {
  RECOMMENDATION_CATEGORY_SEARCH,
  PAGE_HOME,
  RECOMMENDATION_CATEGORY_PICKED,
} from '~hooks/useSegment/eventNames';
import { fireMetric } from '~utils/metrics';
import axios from '~utils/axios/v3';
import apiRoutes from '~utils/apiRoutes';
import { reportError } from '~utils/helpers';
import { useCreateJobDraft } from '~pages/locations/[locationSlug]/utils/helpers';

// Types
import type {
  HomeIndexCategorySearchData,
  CategorySearchResult,
} from '~server/trpc/router/page/home/types';
import type { IkeaPhase } from '~services/IkeaPhaseService';

export type HomeSearchBarProps = {
  defaultSearchResults: HomeIndexCategorySearchData['results'];
  ikeaPhase: IkeaPhase;
  locale: string;
  placeholder: string;
};

// Constants
export const SEARCH_BAR_REFERRER = 'homepage_nextjs_searchbar';
export const SMART_SEARCH_EVENT_KEY = 'smart_search_select_v2';

// Styled components
const StyledSearchBarContainer = styled('div')(
  ({ theme: { breakpoints, spacing } }) => ({
    margin: 'auto',
    marginBottom: spacing(5),
    padding: spacing(0, 1),
    width: '100%',

    [breakpoints.up('sm')]: {
      marginBottom: spacing(8),
      maxWidth: '645px',
      paddingHorizontal: 0,
    },
  })
);

const StyledSearchBarResultsContainer = styled('div')({
  position: 'relative',
  width: 'inherit',
});

// component
const HomeSearchBar = ({
  defaultSearchResults,
  ikeaPhase,
  locale,
  placeholder,
}: HomeSearchBarProps) => {
  const router = useRouter();

  const { trackEvent } = useSegment();
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useDebounce(() => setDebouncedSearchText(searchText), 250, [searchText]);
  const sendSegmentAnalytics = (
    searchResult: CategorySearchResult,
    triggerMethod: string
  ) => {
    const {
      data: { category_id, metro, id: taskTemplateId },
      label,
    } = searchResult;

    trackEvent({
      name: RECOMMENDATION_CATEGORY_PICKED,
      properties: {
        dropdown_search_selected: label,
        job_category: label,
        job_category_id: category_id,
        metro: metro?.name ?? null,
        metro_id: metro?.id ?? null,
        page: PAGE_HOME,
        search_string: searchText,
        task_template_id: taskTemplateId,
        trigger_location: RECOMMENDATION_CATEGORY_SEARCH,
        trigger_method: triggerMethod,
      },
    });
  };

  const { data, isFetching } = trpc.page.home.index_categorySearch.useQuery(
    { locale, text: debouncedSearchText },
    { enabled: debouncedSearchText.length > 3 }
  );

  const pageSearchResults = data?.bff?.results;
  const queryResponse = data?.bff?.query_response;

  const searchResults = pageSearchResults?.length
    ? pageSearchResults
    : defaultSearchResults;

  // Prevent onBlur when clicking on results
  const resultsRef = useRef<HTMLDivElement | null>(null);
  const createDraft = useCreateJobDraft();

  useEffect(() => {
    const ref = resultsRef.current;

    const preventResultsClose = (event: MouseEvent) => {
      event.preventDefault();
    };

    ref?.addEventListener('mousedown', preventResultsClose);

    return () => {
      ref?.removeEventListener('mousedown', preventResultsClose);
    };
  }, [resultsRef]);

  const redirectToResult = async ({
    data: { id: taskTemplateId, category_id },
  }: CategorySearchResult) => {
    // TODO: Remove after improving authorization requirements to /search_job_draft
    // A user may not have the CSRF token required to make POST requests
    // if they have not visited a page in V3. If this cookie does not exist,
    // a call to the /current_user endpoint should set it.
    if (!hasCookie('XSRF-TOKEN')) {
      try {
        await axios.get(apiRoutes.USER.CURRENT);
      } catch (error) {
        reportError(error);
      }
    }

    const jobDraftData = await createDraft({
      locale,
      source: SEARCH_BAR_REFERRER,
      v3CategoryId: category_id,
      v3TaskTemplateId: taskTemplateId,
    });

    if (jobDraftData?.bff) {
      fireMetric(SMART_SEARCH_EVENT_KEY, {
        categorizer_success: queryResponse && JSON.stringify(queryResponse),
        category_id: category_id,
        job_draft_id: jobDraftData.bff.id,
        locale,
        search_term: searchText,
        task_template_id: taskTemplateId,
      });
    }

    const bookingFlowUrl = getBookingFlowUrl(
      category_id,
      ikeaPhase,
      locale,
      taskTemplateId,
      {
        form_referrer: SEARCH_BAR_REFERRER,
        job_draft_guid: jobDraftData?.bff?.guid,
      }
    );

    router.push(bookingFlowUrl, undefined, { locale });
  };

  const onConfirm = () => {
    if (debouncedSearchText.length < 4 || isFetching || !searchResults[0]) {
      return;
    }

    sendSegmentAnalytics(searchResults[0], 'Input Submitted');
    redirectToResult(searchResults[0]);
  };

  const onClick = (searchResult: CategorySearchResult) => {
    sendSegmentAnalytics(searchResult, 'Result Clicked');
    redirectToResult(searchResult);
  };

  return (
    <StyledSearchBarContainer>
      <SearchBar
        data-testid="search-bar"
        onBlur={() => setDropdownVisible(false)}
        onChange={(e) => setSearchText(e.target.value)}
        onClear={() => setSearchText('')}
        onConfirm={onConfirm}
        onFocus={() => setDropdownVisible(true)}
        placeholder={placeholder}
        value={searchText}
      />

      <StyledSearchBarResultsContainer ref={resultsRef}>
        {dropdownVisible && (
          <SearchBarResults
            data={searchResults}
            data-testid="search-bar-results"
            loading={isFetching}
            onClick={onClick}
            resultsEmptyText="No results found."
            searchText={searchText}
            sx={({ breakpoints }) => ({
              [breakpoints.up('sm')]: {
                left: '-8px',
                position: 'absolute',
                width: 'inherit',
                zIndex: '10',
              },
            })}
          />
        )}
      </StyledSearchBarResultsContainer>
    </StyledSearchBarContainer>
  );
};

export default HomeSearchBar;
