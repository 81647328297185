// External
import type { ReactNode } from 'react';
import Image from 'next/image';
import type { ImageProps } from 'next/image';

import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import contentfulImageLoader from '~utils/contentfulImageLoader';

// Types
export interface SectionCompProps {
  $backgroundColor?: string;
}

type SectionBase = {
  backgroundColor?: string;
  children?: ReactNode;
  className?: string;
  height?: string | number;
  id?: string;
};

type SectionImageNone = SectionBase & {
  image?: 'none';
};

type SectionImageBackground = SectionBase & {
  bgImageProps: ImageProps & { alt: string };
  image: 'background';
};

export type SectionProps = SectionImageNone | SectionImageBackground;

// Styled
const SectionComp = styled('section')<SectionCompProps>(
  ({ $backgroundColor }) => ({
    '& > span': {
      zIndex: -1,
    },
    backgroundColor: $backgroundColor,
    position: 'relative',
    zIndex: 0,
  })
);

// Component
export const Section = (props: SectionProps): JSX.Element => {
  const { backgroundColor, children, className, height, id, image } = props;

  let style;

  if (height) style = { height };

  return (
    <SectionComp
      $backgroundColor={backgroundColor}
      className={className}
      id={id}
      sx={style}
    >
      {image === 'background' && (
        <Image
          sizes="100vw"
          {...props.bgImageProps}
          alt={props.bgImageProps.alt}
          loader={contentfulImageLoader}
        />
      )}
      {children}
    </SectionComp>
  );
};

export default Section;
