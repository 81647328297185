// External
import Container from '@taskrabbit/meadow-web/lib/Container';
import Grid from '@taskrabbit/meadow-web/lib/Grid';
import Typography from '@taskrabbit/meadow-web/lib/Typography';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import Image from 'next/image';

// Internal
import contentfulImageLoader from '~utils/contentfulImageLoader';

// Types
import type { HomeIndexPageProps } from '~components/pages/HomeIndexPage';

const StyledImageWrapper = styled('div')(() => ({
  height: '100%',
  minHeight: '86px',
  overflow: 'hidden',
  position: 'relative',
  width: '130px',
}));

const StyledCategoryQuickStatsContainer = styled(Container)(
  ({ theme: { breakpoints, meadow, spacing } }) => ({
    background: `linear-gradient(#FFF, 15%, ${meadow.palette.impact[50]})`,
    padding: spacing(6, 0, 6, 2),

    [breakpoints.up('sm')]: {
      height: '285px',
      padding: 0,
    },

    [breakpoints.up('md')]: {
      background: `linear-gradient(#FFF, 25%, ${meadow.palette.impact[50]})`,
      height: '188px',
    },
  })
);

const StyledContainer = styled(Container)(
  ({ theme: { breakpoints, meadow, spacing } }) => ({
    display: 'flex',

    [breakpoints.up('sm')]: {
      height: '285px',
      paddingLeft: spacing(9),
    },

    [breakpoints.up('md')]: {
      height: '188px',
      paddingLeft: spacing(5),
    },

    [breakpoints.up('lg')]: {
      paddingLeft: spacing(8),
    },
  })
);

const CategoryQuickStats = ({
  listItems,
}: HomeIndexPageProps['bff']['categoryQuickStats']) => {
  return (
    <StyledCategoryQuickStatsContainer disableGutters={true} maxWidth={false}>
      <StyledContainer sx={{ display: 'flex' }}>
        <Grid
          alignContent="space-evenly"
          alignItems="flex-start"
          columnSpacing={{ md: 1, sm: 0, xs: 2 }}
          container
          flexWrap="wrap"
          rowSpacing={{ md: 1, sm: 0, xs: 2 }}
        >
          {listItems.map(({ header, id, imageText, imageUrl, text }) => (
            <Grid item key={id} md={12 / listItems.length} sm={4} xs={6}>
              {imageUrl && imageText ? (
                <StyledImageWrapper
                  sx={{
                    objectFit: 'contain',
                    objectPosition: { md: 'center', xs: 'left' },
                  }}
                >
                  <Image
                    alt={imageText}
                    fill={true}
                    key={imageText}
                    loader={contentfulImageLoader}
                    sizes="128px"
                    src={imageUrl}
                    style={{ objectFit: 'inherit', objectPosition: 'inherit' }}
                  />
                </StyledImageWrapper>
              ) : (
                <>
                  <Typography sx={{ color: 'impact.main' }} variant="subtitle3">
                    {header}
                  </Typography>

                  <Typography
                    sx={{ color: 'primary.main' }}
                    variant="subtitle1"
                  >
                    {text}
                  </Typography>
                </>
              )}
            </Grid>
          ))}
        </Grid>
      </StyledContainer>
    </StyledCategoryQuickStatsContainer>
  );
};

export default CategoryQuickStats;
