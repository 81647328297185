// External
import Container from '@taskrabbit/meadow-web/lib/Container';
import Stack from '@taskrabbit/meadow-web/lib/Stack';
import type { Header } from '~server/types/content';

// Internal
import { StyledHeader } from './index.styled';

const HeroHeadline = ({ header }: Header) => {
  return (
    <Container
      disableGutters={true}
      sx={{
        mb: { md: 7, xs: 3 },
        pl: { sm: 'inherit', xs: 2 },
        pt: { md: 12, xs: 3 },
      }}
    >
      <Stack
        alignItems={{ sm: 'center' }}
        direction="column"
        sx={{ zIndex: 1 }}
      >
        {header.split('\n').map((text) => (
          <StyledHeader
            key={text}
            sx={{ fontSize: { md: 55, xs: 36 }, zIndex: 1 }}
            variant="h1"
          >
            {text}
          </StyledHeader>
        ))}
      </Stack>
    </Container>
  );
};

export default HeroHeadline;
