// External
import Stack from '@taskrabbit/meadow-web/lib/Stack';
import Typography from '@taskrabbit/meadow-web/lib/Typography';
import { useTheme } from '@taskrabbit/meadow-web/lib/Theme';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import type { Entries } from 'type-fest';

// Internal
import { COLOR_KEY_MAP } from './constants';
import CategoryIcon from './CategoryIcon';
import { DesktopWrapper, MobileWrapper } from '../index.styled';

// Types
import type { HomeIndexPageProps } from '~components/pages/HomeIndexPage';
import type { ICategoryFamilyFields } from '~types/_generated/contentful';
import type { HandleCategorySelect } from './CategoriesContainer';

const SmallLine = styled('hr')(({ color }) => ({
  border: `1px solid ${color}`,
  flex: '1px 0 0',
  marginBottom: 0,
  marginTop: '5px',
  width: '40px',
}));

const CategoryFamilyLabel = styled(Typography)(
  ({ theme: { breakpoints, spacing } }) => ({
    alignItems: 'center',
    display: 'flex',
    flex: '40px 1 0',
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    textWrap: 'balance',
  })
);

const StyledCategoryFamilyElementContainer = styled('div')(
  ({
    theme: {
      breakpoints,
      meadow: {
        purpose: { text },
      },
    },
  }) => ({
    '&.selected .SmallLine': {
      opacity: '1',
    },
    '&.unselected:hover .categoryFamilyElementChild': {
      color: text.extraSubtle,
    },
    '&.unselected:hover .SmallLine': {
      border: `1px solid ${text.extraSubtle}`,
      display: 'block',
    },
    '.SmallLine': {
      opacity: '0',
    },
    alignItems: 'center',
    display: 'flex',
    flex: '105px 1 0',
    flexDirection: 'column',
    textAlign: 'center',

    [breakpoints.up('sm')]: {
      width: '20%',
    },
  })
);

const CategoryFamilyElement = ({
  categoryFamilyId,
  displayName,
  onSelect,
  selected,
}: {
  categoryFamilyId: ICategoryFamilyFields['contentfulName'];
  displayName: string;
  onSelect: HandleCategorySelect;
  selected: boolean;
}) => {
  const theme = useTheme();
  const unselectedColor = theme.meadow.purpose.text.subtle;
  const selectedColor: string =
    theme.meadow.purpose.category[COLOR_KEY_MAP[categoryFamilyId]].emphasis;
  const svgColor: string =
    theme.meadow.purpose.category[COLOR_KEY_MAP[categoryFamilyId]].scrim;

  const color = selected ? selectedColor : unselectedColor;

  return (
    <StyledCategoryFamilyElementContainer
      className={selected ? 'selected' : 'unselected'}
      onClick={() => onSelect(categoryFamilyId)}
    >
      <CategoryIcon
        categoryFamilyId={categoryFamilyId}
        selected={selected}
        selectedColor={selectedColor}
        svgColor={svgColor}
        unselectedColor={unselectedColor}
      />
      <CategoryFamilyLabel
        className="categoryFamilyElementChild"
        sx={{ color, cursor: 'pointer' }}
        variant={selected ? 'label1' : 'label2'}
      >
        {displayName}
      </CategoryFamilyLabel>

      <SmallLine className="SmallLine" color={color} />
    </StyledCategoryFamilyElementContainer>
  );
};

const CategoryFamilyRow = ({
  categoryFamilies,
  categoryId,
  onSelect,
}: {
  categoryFamilies: HomeIndexPageProps['bff']['categoryFamilies'];
  categoryId: ICategoryFamilyFields['contentfulName'];
  onSelect: HandleCategorySelect;
}) => (
  <>
    <DesktopWrapper>
      <Stack
        data-testid="categories-icon-container"
        direction="row"
        justifyContent="space-evenly"
        sx={{ cursor: 'pointer' }}
      >
        {(
          Object.entries(categoryFamilies) as Entries<typeof categoryFamilies>
        ).map(([categoryFamilyId, categoryFamily]) => (
          <CategoryFamilyElement
            categoryFamilyId={categoryFamilyId}
            displayName={categoryFamily.name}
            key={categoryFamilyId}
            onSelect={onSelect}
            selected={categoryId === categoryFamilyId}
          />
        ))}
      </Stack>
    </DesktopWrapper>
    <MobileWrapper sx={{ overflow: 'auto' }}>
      <Stack direction="row" flexWrap="nowrap">
        {(
          Object.entries(categoryFamilies) as Entries<typeof categoryFamilies>
        ).map(([categoryFamilyId, categoryFamily]) => (
          <CategoryFamilyElement
            categoryFamilyId={categoryFamilyId}
            displayName={categoryFamily.name}
            key={categoryFamilyId}
            onSelect={onSelect}
            selected={categoryId === categoryFamilyId}
          />
        ))}
      </Stack>
    </MobileWrapper>
  </>
);

export default CategoryFamilyRow;
