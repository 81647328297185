// External
import Container from '@taskrabbit/meadow-web/lib/Container';
import { useTheme } from '@taskrabbit/meadow-web/lib/Theme';
import { useState } from 'react';

// Internal
import { StyledDivider } from '../index.styled';

import CategoryFamilyRow from './CategoryFamilyRow';
import ChildCategoryButtonRow from './ChildCategoryButtonRow';
import CategoryFamilyCopyAndPhoto from './CategoryFamilyCopyAndPhoto';
import { COLOR_KEY_MAP, DEFAULT_CATEGORY } from './constants';
import useSegment from '~hooks/useSegment';
import {
  PAGE_HOME,
  MARKETING_CATEGORY_FAMILY_CLICKED,
  MARKETING_CATEGORY_FAMILY_ICONS_SECTION,
} from '~hooks/useSegment/eventNames';

// Types
import type { HomeIndexPageProps } from '~components/pages/HomeIndexPage';
import type { Link } from '~server/types/content';
import type { ICategoryFamilyFields } from '~types/_generated/contentful';

export type HandleCategorySelect = (
  id: ICategoryFamilyFields['contentfulName']
) => void;

const CategoriesContainer = (
  categoryFamilies: HomeIndexPageProps['bff']['categoryFamilies']
) => {
  const theme = useTheme();
  const [categoryId, setCategoryId] =
    useState<ICategoryFamilyFields['contentfulName']>(DEFAULT_CATEGORY);
  const [categories, setCategories] = useState<Link[]>(
    categoryFamilies[categoryId]['categories']
  );
  const { trackEvent } = useSegment();

  const categoryFamilyName = (id: ICategoryFamilyFields['contentfulName']) =>
    categoryFamilies[id]['name'];

  const sendSegmentAnalytics = (categoryFamilyName: string) => {
    trackEvent({
      name: MARKETING_CATEGORY_FAMILY_CLICKED,
      properties: {
        job_category: null,
        job_category_id: null,
        marketing_category_family_name: categoryFamilyName,
        page: PAGE_HOME,
        trigger_location: MARKETING_CATEGORY_FAMILY_ICONS_SECTION,
      },
    });
  };

  const handleSelect: HandleCategorySelect = (id) => {
    if (categoryId === id) return;

    setCategoryId(id);
    setCategories(categoryFamilies[id]['categories']);
    sendSegmentAnalytics(categoryFamilyName(id));
  };

  const backgroundColor: string =
    theme.meadow.purpose.category[COLOR_KEY_MAP[categoryId]].scrim;

  return (
    <>
      <Container
        sx={({ breakpoints }) => ({
          mt: 0,
          [breakpoints.up('md')]: {
            maxWidth: '100%',
            minWidth: '788px',
            width: 125 * Object.entries(categoryFamilies).length,
          },
          [breakpoints.up('lg')]: {
            maxWidth: '1100px',
            minWidth: 150 * Object.entries(categoryFamilies).length,
          },
        })}
      >
        <CategoryFamilyRow
          categoryFamilies={categoryFamilies}
          categoryId={categoryId}
          onSelect={handleSelect}
        />
        <StyledDivider />
        <ChildCategoryButtonRow
          categories={categories}
          categoryFamilyName={categoryFamilyName(categoryId)}
        />
      </Container>
      <CategoryFamilyCopyAndPhoto
        backgroundColor={backgroundColor}
        {...categoryFamilies[categoryId]}
      />
    </>
  );
};

export default CategoriesContainer;
